export enum LocalStorageEnum {
  AppConfig = 'app_config',
  Corporate = 'corporate',
  Refresh = 'refresh',
  UserLimits = 'user_limits',
  IsOffline = 'is_offline',
  LastDownloadHistogramsParams = 'lastDownloadHistogramsParams',
  LastDownloadDate = 'lastDownloadDate',
  UserPermissions = 'user_permissions',
}
